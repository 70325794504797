import {
  SwanProvider,
  SwanHead,
  SwanFontNameEnum,
  ScreenClassProvider,
  getRootClassNames,
  useBrowserClasses,
} from '@vp/swan';
import React from 'react';
import { Helmet } from 'react-helmet';
import { PageHeader } from '@vp/discovery-pages-component-library';

export interface IPageHeadingPreviewProps {
  title?: string;
  description?: string;
}

export const PageHeadingPreview = (props: IPageHeadingPreviewProps) => {
  const browser = useBrowserClasses();
  const rootClassName = getRootClassNames();

  return (
    <SwanProvider>
      <Helmet htmlAttributes={{ class: browser }} bodyAttributes={{ class: rootClassName }} />
      <SwanHead
        fontNames={[
          SwanFontNameEnum.Graphik,
          SwanFontNameEnum.GraphikBold,
          SwanFontNameEnum.GraphikMedium,
          SwanFontNameEnum.GraphikRegular,
        ]}
        renderWith={Helmet}
        renderStyleContentAsChildren
      />
      <ScreenClassProvider>
        <PageHeader title={props.title ?? ''} description={props.description ?? ''} />
      </ScreenClassProvider>
    </SwanProvider>
  );
};
