import React, { useEffect, useState } from 'react';

import { Box, Form, Text } from '@contentful/f36-components';
import { FieldAppSDK } from '@contentful/app-sdk';
import { useSDK } from '@contentful/react-apps-toolkit';
import { ContentfulRepository } from '../helpers/ContentfulRepository';
import { SectionHeading } from '@contentful/f36-components';
import { PageHeadingPreview } from '../components/PageHeadingPreview';
import { FIELD_HEADER, FIELD_SUB_HEADER } from '../helpers/Constants';
import { ContentfulField } from '../components/ContentfulField';

const Entry = () => {
  const sdk = useSDK<FieldAppSDK>();

  const [lastUpdate, setLastUpdate] = useState<Date>(new Date());
  const [availableLanguages, setAvailableLanguages] = useState<string[]>([]);

  const contentfulRepository = new ContentfulRepository();

  useEffect(() => {
    const dettachableEvents: Function[] = [];
    dettachableEvents.push(sdk.editor.onLocaleSettingsChanged(onLocaleSettingsChanged));

    return () => {
      dettachableEvents.forEach((event) => event());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDataChanged = () => {
    setLastUpdate(new Date());
  };

  const onLocaleSettingsChanged = (data: any) => {
    const { mode, focused, active } = data;
    if (mode === 'single') {
      setAvailableLanguages([focused]);
    } else if (mode === 'multi') {
      setAvailableLanguages(active);
    }
  };

  const renderPreview = (locale: string) => {
    return (
      <React.Fragment key={`preview-${locale}`}>
        <Text fontWeight={'fontWeightMedium'}>{sdk.locales.names[locale]}</Text>
        <PageHeadingPreview
          title={contentfulRepository.getEntryFieldValueForLocale(FIELD_HEADER, locale)}
          description={contentfulRepository.getEntryFieldValueForLocale(FIELD_SUB_HEADER, locale)}
        />
      </React.Fragment>
    );
  };

  return (
    <Box padding={'spacingM'}>
      <SectionHeading>Information</SectionHeading>
      <Box display={'none'}>{lastUpdate.toString()}</Box>

      <Form>
        <ContentfulField
          sdk={sdk}
          fieldName={FIELD_HEADER}
          fieldLabel="Header"
          onValueChanged={onDataChanged}
          availableLanguages={availableLanguages}
        />
        <ContentfulField
          sdk={sdk}
          fieldName={FIELD_SUB_HEADER}
          fieldLabel="Sub-Header"
          onValueChanged={onDataChanged}
          availableLanguages={availableLanguages}
        />
      </Form>

      <SectionHeading>Preview</SectionHeading>

      {availableLanguages.indexOf(sdk.locales.default) !== -1 && renderPreview(sdk.locales.default)}
      {availableLanguages
        .filter((x) => x !== sdk.locales.default)
        .map((locale) => renderPreview(locale))}
    </Box>
  );
};

export default Entry;
